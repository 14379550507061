<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-title p-3 py-2 border-bottom">
            <div class="d-lg-flex align-items-center">
              <div class="d-flex align-items-center mb-2 mb-lg-0">
                <div class="me-2">
                  <i class="uil uil-setting display-6 text-primary"></i>
                </div>
                <div class="">
                  <h4 class="fw-medium font-size-20 text-dark mb-0">
                    {{ title }}
                  </h4>
                  <small class="d-block font-size-13 text-muted fw-normal">Website, Callback Url and Whitelist IP Settings</small>
                </div>
              </div>
            </div>
            
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-lg-12">
                <div class="mt-1">
                  
                    <div class="row">
                      <div class="col-md-6 col-lg-3 col-12">
                        <h5 class="font-size-14 mb-1 text-dark">Login Username</h5>
                        <p class="mb-3 text-primary">{{username || '-'}}</p>
                      </div>
                      <div class="col-md-6 col-lg-3 col-12">
                        <h5 class="font-size-14 mb-1 text-dark">Code for Support</h5>
                        <p class="mb-3 text-primary">{{code_for_support_skype || '-'}}</p>
                      </div>
                      <div class="col-md-6 col-lg-3 col-12">
                        <h5 class="font-size-14 mb-1 text-dark">Merchant Code</h5>
                        <p class="mb-3 text-primary">{{merchant_code || '-'}}</p>
                      </div>
                      <div class="col-md-6 col-lg-3 col-12">
                        <h5 class="font-size-14 mb-1 text-dark">Merchant Key</h5>
                        <p class="mb-3 text-primary">{{merchant_key || '-'}}</p>
                      </div>
                     
                    </div>
                  <div class="row">
                    <div class="mb-3 col-12 col-lg-6">
                      <label>Name</label>
                      <input
                          v-model="formData.name"
                          type="text"
                          class="form-control"
                          placeholder="Name"
                          name="name"
                          disabled="true"
                          :class="{
                          'is-invalid':
                          submitted && $v.formData.name.$error,
                          }"
                          />
                      <div
                          v-if="submitted && $v.formData.name.$error"
                          class="invalid-feedback"
                          >
                          <span v-if="!$v.formData.name.required"
                            >Name is required.</span
                            >
                      </div>
                    </div>
                    <div class="mb-3 col-12 col-lg-6">
                      <label>Website</label>
                      <input
                          v-model="formData.websiteUrl"
                          type="text"
                          class="form-control"
                          placeholder="Website"
                          name="WebsiteUrl"
                          :class="{
                          'is-invalid':
                          submitted && $v.formData.websiteUrl.$error,
                          }"
                          />
                      <div
                          v-if="submitted && $v.formData.websiteUrl.$error"
                          class="invalid-feedback"
                          >
                          <span v-if="!$v.formData.websiteUrl.required"
                            >Website url is required.</span
                            >
                          <span v-else-if="!$v.formData.websiteUrl.url"
                            >Please enter a valid website url.</span
                            >
                      </div>
                    </div>
                    <div class="mb-3 col-12 col-md-6">
                      <label>Callback Url (Reponse Url)</label>
                      <input
                          type="text"
                          v-model.trim="formData.callBackUrl"
                          class="form-control"
                          placeholder="Callback Url (Reponse Url)"
                          name="callBackUrl"
                          />
                    </div>
                    <div class="mb-4 col-12 col-md-6">
                      <label>Whitelist IP Addresses <small class="fw-normal font-size-11 text-muted">Separated by commas (,)</small></label>
                      <input
                          v-model.trim="formData.whitelistIP"
                          type="text"
                          class="form-control"
                          placeholder="Enter multiple IP addresses separated by commas (,)"
                          name="whitelistIP"
                          />
                    </div>
                    
                    <div class="mb-3 d-flex" v-if="create_modify_permission">
                      <button type="button" @click="submit()" class="btn btn-info me-2" :disabled="loading || buttonLoading">
                      <span v-if="buttonLoading"> Updating...</span>
                      <span v-else>  Update Setting </span>
                      <span class="spinner-border spinner-border-sm ms-2" v-if="buttonLoading" role="status" aria-hidden="true"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Lottie :path="''" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import { required} from "vuelidate/lib/validators";
/**
 * Merchant Setting
 */
 const PageTitle = "Merchant Setting"

export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common
  },
  page() {
    return {
      title: PageTitle,
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: appConfig.pageTitle,
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      username: "",
      merchant_code: "",
      merchant_key: "",
      merchant_account_db_code: "",
      account_type:"",
      code_for_support_skype:"",
      formData: {
        id:"",
        name:"",
        websiteUrl:"",
        whitelistIP:"",
        callBackUrl:"",
      },
      submitted:false,
      loading:false,
      buttonLoading:false,
      deleteLoading:false,
      create_modify_permission:false
    };
  },
  validations: {
    formData: {
      name: {
        required,
      },
      websiteUrl: {
        required,
      },
    },
  },
  middleware: "authentication",
  async mounted(){
   
    this.title = PageTitle
    this.items[1].text = PageTitle
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    await this.reload()
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessUsername = this.$refs.commonFunc.getUsername()
    if (this.account_type=='merchant'){
      //this.getMerchantData();
      this.getData();
    }
    
  }, 
  created(){
   
  },
  methods:{
    getData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessUsername",  this.accessUsername);
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("resellerId", '');
        bodyFormData.append("merchantId", this.merchant_account_db_code);
        axios({
            method: "post",
            url: appConfig.APIHostAdmin + 'controller/admin/getMerchantDetail',
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
             
             
             var dataReturn ={
                id: resData.data.account_db_code,
                name: resData.data.account_name,
                website_url:resData.data.website_url,
                whitelist_ip:resData.data.whitelist_ip,
                callback_url:resData.data.callback_url,
              }
              console.log(resData)
              this.formData.id = dataReturn.id
              this.formData.name = dataReturn.name
              this.formData.websiteUrl = dataReturn.website_url
              this.formData.whitelistIP = dataReturn.whitelist_ip
              this.formData.callBackUrl = dataReturn.callback_url
            } 
            else if (resData.status == 440){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                localStorage.clear();
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    submit(){
        this.submitted = true
        this.$v.formData.$touch();
        console.log((this.$v.formData.$invalid))
        if (this.$v.formData.$invalid) {
          return;
        } else {
          this.$Progress.start();
          this.buttonLoading = true;
          var bodyFormData = new FormData();
          bodyFormData.append("accessToken", this.accessToken);
          bodyFormData.append("accessUsername", this.accessUsername);
          bodyFormData.append("merchantId", this.formData.id);
          bodyFormData.append("name", this.formData.name);
          bodyFormData.append("websiteUrl", this.formData.websiteUrl);
          bodyFormData.append("whitelistIP", this.formData.whitelistIP);
          bodyFormData.append("callbackUrl", this.formData.callBackUrl);
          axios({
              method: "post",
              url: appConfig.APIHostAdmin + 'controller/admin/updateTechnicalMerchant',
              data: bodyFormData,
              headers: { "Content-Type": "multipart/form-data" },
          })
          .then((response)=>{
              
              var resData = response.data;
              if (resData.status == 200) {
                console.log(resData)
                Swal.fire({
                  icon: 'success',
                  title: 'Merchant Setting Updated',
                  html: 'Merchant setting has been successfully updated!',
                  confirmButtonColor: '#FA6541',
                  confirmButtonText: this.$t('siteLang.Done')
                }).then(() => {
                  console.log("success")
                  this.getData()
                })
                const updateData = {
                    "name": this.formData.name,
                }
                this.$refs.commonFunc.updateData(JSON.stringify(updateData))
                this.reload()
                
              } 
              else if (resData.status == 440){
                Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                  localStorage.clear();
                  this.$router.push({
                      path: "/login",
                });
              }
              else {
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${resData.message}.`,
                    confirmButtonColor: '#222',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
              }
              this.buttonLoading = false;
              this.submitted = false
              this.$Progress.finish();
            })
            .catch((error)=> {
                this.buttonLoading = false;
                this.submitted = false
                this.$Progress.finish();
                console.log(error);
        });
      }
    },
    async reload() {
      try {
        await this.$nextTick(); // Wait for the component to be fully mounted

        const commonFunc = this.$refs.commonFunc;
        if (commonFunc && commonFunc.getFullData) {
          const data = await commonFunc.getFullData();
          this.account_type = data.account_type;
          this.username = data.username;
          this.merchant_key = data.merchant_info.merchant_key;
          this.merchant_code = data.merchant_info.merchant_code;
          if (this.account_type=='reseller'){
            this.code_for_support_skype = data.reseller_info.code_for_support_skype;
          }
          else if (this.account_type=='merchant'){
            this.code_for_support_skype = data.merchant_info.code_for_support_skype;
          }
          else{
            this.code_for_support_skype = ''
          }
          this.merchant_account_db_code = data.merchant_info.account_db_code;
          const permission = data.modules.filter(e => e.name==='technical')
            if (permission) {
              this.create_modify_permission = permission[0].create_modify_permission==0 ? false : true; 
              console.log(this.create_modify_permission)
            } else {
              console.log('Module with name "technical" not found.');
            }
        } else {
          // Handle the case when commonFunc or getFullData is undefined
          // Perform appropriate actions or show a fallback message to the user
        }
      } catch (error) {
        // Handle the error silently without propagating it further
      }
    },
  }
};
</script>